.loading{
    font-family: 'DM Sans';
    font-size: 1.5625rem !important;
    color: #BB3631 !important;
}

.tabs {
    position: relative;
    display: flex;
    padding-left: 0 !important;
    border-bottom: 1px solid rgba(83, 6, 6, 0.26);
}
:root{
    --onc--color-text:#CCC4A7;
    --onc-color-yellow:#BB3631;
    --onc-color-bg-pages:#F4F3F3;
    --onc-color-bg: #42210B;
    --onc-color-bg-dark: #42210B;
    --onc-color-txt: #42210B;
}

.tabs li {
    display: block;
    padding: 0.5rem 0rem;
    font-family: 'DM SANS';
    font-size: 1.25rem;
    font-weight: 400;
    background: transparent;
    color:#42210B;
    margin-right: 0.5rem;
    border-radius: 0.375rem;
    cursor: pointer;
}

.tabs > li{
    width: 4.6875rem !important;
}

.tabs > li:nth-child(2){
    width: 5.625rem !important;
}


.tabs li.active {
    position: relative;
    font-family: 'DM SANS';
    font-size: 1.25rem;
    font-weight: 700;
    background: transparent;
    color: #BB3631;
}

 li.active:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--onc-color-yellow);
    width: 100%;
    height: 0.25rem;
}


.tabs li:hover {
    color: #CCC4A7;
}

.tab_content {
    padding: 0.625rem;
    background: #42210B;
    margin-top: 0.625rem;
    border-radius: 0.3125rem;
    min-height: 11.875rem;
}

/* .line {
    left: 0;
    position: absolute;
    bottom: -0.0625rem;
    width: 4.6875rem;
    height: 0.25rem;
    background-color: var(--onc-color-yellow);
    transition: all 300ms ease-in-out;
} */

.tabs-content{
    flex: 1 1 auto;
    overflow: auto;
    overflow-x: hidden;
}

.accordion-item {
    border-radius: 0.25rem !important;
    background-color: transparent;
    margin-bottom: 1rem;
}

.accordion-flush div:last-child .accordion-item {
    margin-bottom: 0;
}


.accordion-button {
    padding: 0.3125rem 1rem;
    border-radius: 0.25rem !important;
    font-size: 1.625rem;
    font-weight: 600;
    color: #42210B;
    background-color: #CCC4A7;
    transition: 200ms all ease;
}

.accordion-button:hover{
    background-color: rgba(255, 255, 255, 0.40);
    transition: 200ms all ease;
}

.accordion-button::after {
    filter: invert(1);
    opacity: 30%;
}

.accordion-button:focus {
    box-shadow: 0px 0px 0px 4px #8d8765 !important
}

.accordion-button:not(.collapsed) {
    background-color: var(--onc-color-yellow);
    color: var(--onc-color-txt);
    box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
    filter: grayscale(1);
    mix-blend-mode: multiply;
    opacity: 100%;
}

.accordion-collapse.collapse.show {
    padding-top: 0.75rem;
}

.accordion-body {
    padding: 0;
}


.nft-card {
    height: 8rem;
    background-color: rgba(209, 74, 74, 0.08);
    border: 1px solid rgba(83, 6, 6, 0.26);
    border-radius: 0.25rem;
}


.nft-card-img-container {
    padding: 0;
    height: 100%;
    text-align: left;
    gap: 1.25rem;
}


.nft-card-info .benefit-card-title {
    font-family: 'Proxima Nova' !important;
    font-size: 1.25rem !important;
    font-weight: bold;
    color: #CCC4A7;
    text-align: left;
    margin-bottom: 0.75rem;
}

.nft-card-info .benefit-card-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
    overflow: hidden;
    text-align: left !important;
    color: #42210B !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    margin-right: 1rem;
}


.nft-card .monetary-value{
    font-family: 'Proxima Nova' !important;
    color: var(--onc-color-yellow) !important;
    font-size: 1.25rem !important;
    font-weight: bold;
    margin-bottom: 0.5rem;
}




/* Inactive tab */
.myClass ul>li>a {
    background-color: white;
    color: red;
}


/* Active tab */
.myClass ul>li.active>a,
.myClass ul>li.active>a:hover,
.myClass ul>li.active>a:focus {
    background-color: red;
    color: white;
}